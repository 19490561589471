<template>
	<div class="sign-up-input mx-auto w-full">
		<div class="flex flex-col">
			<label
				:for="inputData.name"
				class="text-sm text-pink-main mb-1"
			>
				{{ `${inputData.title} ${inputData.mandatory ? '*' : ''}` }}
			</label>

			<validation-field
				v-slot="{ field, errors, meta }"
				v-model="computedInputData.value"
				:name="inputData.name"
				as="div"
				:class="`relative ${inputData.customCss || ''}`"
			>
				<div class="flex flex-row items-end">
					<div
						v-if="inputData.type === 'birthdate'"
						class="w-full"
					>
						<el-date-picker
							v-model="computedInputData.value"
							:teleported="false"
							:autocomplete="inputData.autocomplete"
							class="w-full"
							popper-class="rounded-lg"
							:placeholder="inputData.placeholder"
							:format="methodFormatDatePicker()"
							style="width: 100% !important"
							@change="methodUpdateDate"
						/>
					</div>

					<el-input
						v-else
						v-bind="field"
						v-model="computedInputData.value"
						:placeholder="inputData.placeholder"
						:disabled="computedInputData?.disabled"
						class="signup-input"
						:type="
							showPassword && inputData.isSecret
								? 'text'
								: inputData.isSecret
									? 'password'
									: 'text'
						"
						:autocomplete="inputData.autocomplete"
						:name="inputData.name"
						@change="inputData.methodEmitChange"
					>
						<template #suffix>
							<div
								v-if="inputData.isSecret"
								class="secret-icon"
								@click="showPassword = !showPassword"
							>
								<img
									class="eye-icon w-6 pr-2"
									:src="
										showPassword
											? '/static/images/svg/open_eye.svg'
											: '/static/images/svg/closed_eye.svg'
									"
								>
							</div>
						</template>
					</el-input>

					<i
						v-if="meta.valid && !isEmpty(inputData.value) && inputData.name !== 'invitation-code'"
						class="gui-yes flex items-center text-gray-strong text-lg w-0 h-10 relative"
						style="right: -10px"
					/>
				</div>
				<div
					v-if="!inputData.isPassword"
					style="min-height: 1rem"
					class="mt-3"
				>
					<transition
						v-if="errors.length > 0 && meta.touched"
						name="fade-in"
						mode="out-in"
					>
						<div class="flex text-sm text-gray-strong pb-6">
							<i
								class="gui-no text-gray-dark mr-1"
								style="margin-top: 3px"
							/>
							<span>
								{{ errors[0] }}

								<router-link
									v-if="errors[0] === $t('VEE_VALIDATE.EXISTING_EMAIL')"
									:to="{ name: 'login' }"
									class="underline"
								>
									{{ $t(`APP.SIGNUP.LOGIN`) }}
								</router-link>
							</span>
						</div>
					</transition>
				</div>
			</validation-field>
		</div>
	</div>
</template>

<script>
import { Field as ValidationField } from 'vee-validate'
import { getDisplayDateFromLocalStorage } from '@/utils/dateFnsHelper'
import isEmpty from 'lodash/isEmpty'

export default {
	components: {
		ValidationField,
	},
	props: {
		inputData: { type: Object, required: true },
		byPassSignup: { type: Boolean, required: false },
	},
	emits: ['update:by-pass-signup'],
	data() {
		return {
			inputValue: '',
			showPassword: false,
		}
	},
	computed: {
		computedInputData() {
			return this.inputData
		},
		computedInputDataValue() {
			return this.inputData.value
		},
		computedByPassSignup: {
			get() {
				return this.byPassSignup
			},
			set(value) {
				this.$emit('update:by-pass-signup', value)
			},
		},
	},
	watch: {
		computedInputDataValue() {
			this.computedByPassSignup = false
		},
	},

	methods: {
		isEmpty,
		methodFormatDatePicker() {
			return getDisplayDateFromLocalStorage().toUpperCase()
		},

		methodUpdateDate(value) {
			if (value) {
				const date = new Date(value)
				this.inputData.methodEmitChange(date.toISOString())
				this.computedInputData.value = date.toISOString()
			}
		},
	},
}
</script>

<style>
.sign-up-input .el-input__wrapper {
	height: 40px;
}
</style>
